import React from 'react';
import PropTypes from 'prop-types';
import { message, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages } from '../../constants/messages';
import { GetClientDevicesForInvoice } from './actions';
import DevicePicker from '../Devices/DevicePicker';
import LeasedDeviceIcon from './InvoiceDeviceIcons/Leased';
import JammingDeviceIcon from './InvoiceDeviceIcons/Jamming';
import ThermometerDeviceIcon from './InvoiceDeviceIcons/Thermometer';
import CameraDeviceIcon from './InvoiceDeviceIcons/Camera';
import TankDeviceIcon from './InvoiceDeviceIcons/Tank';
import MaintenanceDeviceIcon from './InvoiceDeviceIcons/Maintenance';
import { VideoCameraOutlined } from '@ant-design/icons';

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class InvoiceDevicesPicker extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			devices: []
		};
	}

	componentDidMount() {
		this.downloadDevices();
	}

	componentDidUpdate(prevProps) {
		const { props, downloadDevices } = this;
		const { clientId: currentClientId, form } = props;
		const { clientId: previousClientId } = prevProps;
		const { resetFields } = form;
		if (currentClientId !== previousClientId) {
			downloadDevices();
			resetFields(['deviceIds']);
		}
	}

	downloadDevices = () => {
		const { props, getIntl, setLoading } = this;
		const { clientId } = props;
		setLoading(true);
		GetClientDevicesForInvoice(clientId)
			.then(devices => this.setState({ devices, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadDevicesError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	renderIcons = (device) => {
		const { isMaintenance, isLeased, hasTank, hasCamera, hasThermometer, hasJamming, hasCanbus, hasVideoCamera } = device;
		if (isMaintenance || isLeased || hasTank || hasCamera || hasThermometer || hasJamming || hasCanbus, hasVideoCamera )
			return (
				<div>
					&nbsp;&nbsp;&nbsp;
					{isMaintenance ? <MaintenanceDeviceIcon /> : null}
					{isLeased ? <LeasedDeviceIcon /> : null}
					{hasTank ? <TankDeviceIcon /> : null}
					{hasCamera ? <CameraDeviceIcon /> : null}
					{hasThermometer ? <ThermometerDeviceIcon /> : null}
					{hasJamming ? <JammingDeviceIcon /> : null}
					{hasCanbus ? <span className='icon-can' /> : null}
					{hasVideoCamera ? <VideoCameraOutlined />: null}
				</div>
			);
	}

	renderDevicePicker = () => {
		const { state, props, renderIcons } = this;
		const { devices } = state;
		const { form, devicesSelected } = props;
		return (
			<DevicePicker
				form={form}
				devices={devices}
				fieldName="deviceIds"
				maxTagCount={10}
				extraRender={renderIcons}
				devicesSelected={devicesSelected}
			/>
		);
	}

	fullRender = () => {
		const { state, renderDevicePicker } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				{renderDevicePicker()}
			</Spin>
		);
	}
	
	render() {
		return this.fullRender();
	}
}

DevicePicker.defaultProps = {
	clientId: 0
};

InvoiceDevicesPicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	clientId: PropTypes.number,
	devicesSelected: PropTypes.array,
};

export default injectIntl(InvoiceDevicesPicker);